/* eslint-env browser */

import * as d3 from "d3";
import {DateTime, Duration, Interval} from "luxon";

/**
 * @author Dimitry Kudrayvtsev
 * @version 2.0
 *
 * Ported to d3 v4 by Keyvan Fatehi on October 16th, 2016
 *
 */

export default function ganttBoxPlot(parentElement, elementId, dayOfStudyData = {}, options = {}) {
  const MIN_EVENT_WIDTH = 1;

  const margin = {
    top: 20,
    right: 30,
    bottom: 20,
    left: 60,
  };

  if (parentElement.id === "heartRateTrendContainer") {
    // Margins for Generated Report
    margin.right = 10;
    margin.left = 50;
  }
  let timeDomainStart = DateTime.now().minus({days: 3});
  let timeDomainEnd = DateTime.now().plus({hours: 3});
  // Default is Daily Trend Values
  let boxWidthMs = Duration.fromObject({minutes: 30}).as("milliseconds");
  let graphDuration = Duration.fromObject({days: 1}).as("milliseconds");
  let tickFrequency = d3.utcHour.every(1);
  let tickFormat = d3.timeFormat("%H:%M");
  let labelFrequency = d3.utcHour.every(4);

  const height = 240;
  const width = parentElement.offsetWidth - margin.right - margin.left - 20;
  let x;
  let y;
  let xAxis;
  let yAxis;

  initAxis();

  function initAxis(maxValueY = 0) {
    x = d3
      .scaleUtc()
      .domain([timeDomainStart.toJSDate(), timeDomainEnd.toJSDate()])
      .range([0, width])
      .clamp(true);

    y = d3
      .scaleLinear()
      .domain([0, maxValueY])
      .range([height - margin.top - margin.bottom, 0]);

    xAxis = d3.axisBottom().ticks(labelFrequency).scale(x).tickFormat(tickFormat).tickSize(0);

    yAxis = d3.axisLeft().scale(y).tickSize(2);
  }

  function configuredGanttBoxPlot(averageHeartRates, eventsToExclude, showRawFunc, isDisabled = false) {
    const disabledClass = isDisabled ? " disabled" : "";

    const maxValueY = averageHeartRates.reduce((acc, val) => Math.max(acc, val.avg), 0) * 1.1;
    initAxis(maxValueY);

    const [topY, bottomY] = y.range(); // Top and Bottom

    const svg = d3
      .select(`#${elementId}`)
      .append("svg")
      .attr("class", "chart")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      // Subtract 27 from the right margin so that the chart lines up with the arrhythmia timeline
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right - 27} ${height + margin.top + margin.bottom}`
      )
      .classed("ganttChartSvg", true)
      .append("g")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .attr("preserveAspectRatio", "none");

    // Make vertical gridlines
    svg
      .append("g")
      .attr("class", `grid${disabledClass}`)
      .attr("transform", `translate(0, ${height - margin.top - margin.bottom})`)
      .call(
        makeXGridlines()
          .tickSize(-(height - margin.top - margin.bottom))
          .tickFormat("")
      );

    // Make horizontal gridlines
    svg
      .append("g")
      .attr("class", `grid${disabledClass}`)
      .attr("transform", `translate(${width}, 0)`)
      .call(makeYGridlines().tickSize(width).tickFormat(""));

    // Add date lines
    const isDailyTrend = timeDomainEnd - timeDomainStart === Duration.fromObject({days: 1}).toMillis();
    const days = Interval.fromDateTimes(timeDomainStart, timeDomainEnd)
      .splitBy({days: 1})
      .map((i) => (isDailyTrend ? i.end.startOf("day") : i.start));
    days.forEach((day) => {
      let dayOfStudyText = "";

      if (
        dayOfStudyData &&
        Interval.fromDateTimes(dayOfStudyData.firstDay, dayOfStudyData.lastDay).contains(day)
      ) {
        const diffInDays = day.diff(dayOfStudyData.firstDay, "days").toObject().days + 1;
        dayOfStudyText = ` - d${diffInDays}`;
      }

      _showDateLines(svg, x(day.toJSDate()), bottomY, topY, day, dayOfStudyText, disabledClass);
    });

    // Add in the bars representing artifact (unreadable ECG data)
    eventsToExclude.forEach((unreadableEvent) => {
      let eventWidth = x(new Date(unreadableEvent.endTime)) - x(new Date(unreadableEvent.startTime));
      if (eventWidth < MIN_EVENT_WIDTH) {
        eventWidth = MIN_EVENT_WIDTH;
      }
      svg
        .append("rect")
        .attr("x", x(new Date(unreadableEvent.startTime)))
        .attr("y", bottomY)
        .attr("width", eventWidth)
        .attr("height", topY)
        .classed(`artifactRegion${disabledClass}`, true);
    });

    // Reference Lines
    [60, 100].forEach((referenceLine) =>
      svg
        .append("line")
        .attr("x1", x.range()[0])
        .attr("x2", x.range()[1])
        .attr("y1", y(referenceLine))
        .attr("y2", y(referenceLine))
        .classed(`referenceLine${disabledClass}`, true)
    );

    // Sets up and shows the average Heart Rate Line
    _showAverageHeartRateLine(svg, averageHeartRates, x, y, disabledClass);

    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${height - margin.top - margin.bottom})`)
      .transition()
      .call(xAxis);

    svg.append("g").attr("class", "y axis").transition().call(yAxis);

    // Draw the hover indicator rectangle and handle click functionality
    if (showRawFunc) {
      const graphHeight = height - margin.top - margin.bottom;
      const boxCount = graphDuration / boxWidthMs;
      const boxWidth = width / boxCount;

      const dataDomainStart = averageHeartRates[0].mt;
      const dataDomainEnd = averageHeartRates[averageHeartRates.length - 1].mt;

      const minHoverIndex = Math.floor((dataDomainStart - timeDomainStart) / boxWidthMs);
      const maxHoverIndex = Math.floor((dataDomainEnd - timeDomainStart) / boxWidthMs);

      const getHoveredIndex = (event) => {
        const [mouseX, mouseY] = d3.pointer(event);
        const hoveredIndex = Math.floor(mouseX / boxWidth);

        if (
          hoveredIndex >= minHoverIndex &&
          hoveredIndex <= maxHoverIndex &&
          mouseY >= 0 &&
          mouseY <= graphHeight
        ) {
          return hoveredIndex;
        }

        // Returns null if outside the clickable area
        return null;
      };

      // Register mousemove event to show hover-indicator
      svg.on("mousemove", function mouseMoveEvent(event) {
        Array.from(document.getElementsByClassName("heart-rate-trend-hover")).forEach((e) => e.remove());

        const hoveredIndex = getHoveredIndex(event);
        if (hoveredIndex !== null) {
          svg
            .append("rect")
            .attr("x", boxWidth * hoveredIndex)
            .attr("y", 0)
            .attr("width", boxWidth)
            .attr("height", graphHeight)
            .classed("heart-rate-trend-hover", true);
        }
      });

      // Register Click events
      svg.on("click", function mouseClickEvent(event) {
        const hoveredIndex = getHoveredIndex(event);
        if (hoveredIndex !== null) {
          const startTime = timeDomainStart + hoveredIndex * boxWidthMs;
          const endTime = startTime + boxWidthMs;
          showRawFunc(startTime, endTime);
        }
      });
    }

    return svg;
  }

  // gridlines in x axis function
  function makeXGridlines() {
    return d3.axisBottom(x).ticks(tickFrequency);
  }

  //  gridlines in y axis function
  function makeYGridlines() {
    return d3.axisLeft(y);
  }

  // Date Indicators with triangle markers
  function _showDateLines(svg, x1, y1, y2, date, dateText, disabledClass) {
    const color = disabledClass === "" ? "#76858c" : "#c7c7c7";
    const strokeWidth = 2;
    const triangleHeight = 20;
    const triangleWidth = 15;

    svg
      .append("line")
      .attr("stroke", color)
      .attr("stroke-width", strokeWidth)
      .attr("x1", x1)
      .attr("x2", x1)
      .attr("y1", y1 - triangleWidth / 2)
      .attr("y2", y2);
    svg
      .append("text")
      .attr("class", `dateLineText${disabledClass}`)
      .attr("text-anchor", "left")
      .attr("x", x1 + triangleWidth + 5)
      .attr("y", y1 - triangleWidth / 2)
      .text(`${date.toFormat("LLL dd")}${dateText}`);

    // m is pen down, capital means absolute coordinates
    // l is lineto, lowercase means relative coordinates
    // h and v are horizontal and vertical lineto
    let triangleData = `M ${x1 + strokeWidth / 2} 0`;
    triangleData += ` l ${triangleWidth} -${triangleHeight / 2}`;
    triangleData += ` l -${triangleWidth} -${triangleHeight / 2}`;
    triangleData += ` h -${strokeWidth}`;
    triangleData += ` v ${triangleHeight}`;
    svg.append("path").attr("d", triangleData).attr("fill", color);
  }

  function _showAverageHeartRateLine(svg, averageHeartRates, xScale, yScale, disabledClass) {
    let src;

    averageHeartRates.forEach((point) => {
      let dest;

      // if the average is <= 0, the line segment will not be drawn
      if (point.avg > 0) {
        dest = {
          x: xScale(point.mt), // use the midpoint of the averages
          y: yScale(point.avg),
        };
      }

      // Only Draw the Line Segment if both Source and Destination have
      // valid mean values (skips the first line segment)
      if (src && dest) {
        svg
          .append("line")
          .attr("class", `averageHeartRateLine${disabledClass}`)
          .attr("x1", src.x)
          .attr("y1", src.y)
          .attr("x2", dest.x)
          .attr("y2", dest.y);
      }
      src = dest;
    });
  }

  boxWidthMs = options.boxWidthMs;
  graphDuration = options.graphDuration;
  tickFrequency = options.tickFrequency;
  tickFormat = options.tickFormat;
  labelFrequency = options.labelFrequency;
  [timeDomainStart, timeDomainEnd] = options.timeDomain;

  return configuredGanttBoxPlot;
}
