import * as d3 from "d3";
/* eslint-disable */
/**
 * @author Dimitry Kudrayvtsev
 * @version 2.0
 *
 * Ported to d3 v4 by Keyvan Fatehi on October 16th, 2016
 *
 */

export default function ganttLeads(parentElement, elementId, channelCount, options = {}) {
  var FIT_TIME_DOMAIN_MODE = "fit";
  var margin = {
    top: 0,
    right: 50,
    bottom: 20,
    left: 75,
  };
  var display = "rect";
  var timeDomainStart = d3.timeDay.offset(new Date(), -3);
  var timeDomainEnd = d3.timeHour.offset(new Date(), +3);
  var timeDomainMode = FIT_TIME_DOMAIN_MODE; // fixed or fit
  var channels = [];
  var tickFrequency = d3.timeMinute.every(10);
  var tickFormat = d3.timeFormat("%H:%M");

  var height = 180;
  var width = parentElement.offsetWidth - margin.right - margin.left - 20;
  var itemHeight = ((height - margin.top - margin.bottom) / channelCount) * 0.6;

  function keyFunction(d) {
    return d.startDate + d.taskName + d.endDate;
  }

  function rectTransform(d) {
    const offset = Math.round(((height - margin.top - margin.bottom) / channelCount) * 0.2);
    const yPos = y(d.taskName) + offset;

    return "translate(" + x(d.startDate) + "," + yPos + ")";
  }

  var x, y, xAxis, yAxis;

  initAxis();

  function initAxis() {
    x = d3.scaleTime().domain([timeDomainStart, timeDomainEnd]).range([0, width]).clamp(true);

    y = d3
      .scaleBand()
      .domain(channels)
      .rangeRound([0, height - margin.top - margin.bottom], 0.1);

    xAxis = d3.axisBottom().ticks(tickFrequency).scale(x).tickFormat(tickFormat).tickSize(0);

    yAxis = d3.axisLeft().scale(y).tickSize(0);
  }

  function ganttLeads(channelStatuses) {
    initAxis();

    var svg = d3
      .select(`#${elementId}`)
      .append("svg")
      .attr("class", "chart")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      // Subtract 27 from the right margin so that the chart lines up with the heart rate trend
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right - 27} ${height + margin.top + margin.bottom}`
      )
      .classed("ganttChartSvg", true)
      .append("g")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .attr("preserveAspectRatio", "none");

    // Make grid and vertical gridlines
    svg
      .append("g")
      .attr("class", "leadGrid")
      .attr("transform", "translate(0, " + (height - margin.top - margin.bottom) + ")")
      .call(
        _make_x_gridlines()
          .tickSize(-(height - margin.top - margin.bottom))
          .tickFormat("")
      );

    svg
      .selectAll(".chart")
      .data(channelStatuses, keyFunction)
      .enter()
      .append(function (d, i) {
        return document.createElementNS(d3.namespaces.svg, "display" in d ? d.display : display);
      })
      .attr("cx", 0)
      .attr("cy", 10)
      .attr("r", itemHeight / 2)
      .attr("class", function (d) {
        let channelClass = "connectedChannel";
        if (d.status === "disconnected") {
          channelClass = "disconnectedChannel";
        }
        return channelClass;
      })
      .attr("y", 0)
      .attr("transform", rectTransform)
      .attr("height", itemHeight)
      .attr("width", function (d) {
        return x(d.endDate) - x(d.startDate);
      })
      .style("stroke-dasharray", function (d) {
        let eventWidth = x(d.endDate) - x(d.startDate);
        return `${eventWidth} ${itemHeight}`;
      });

    svg
      .append("g")
      .attr("class", "x axis leadGridAxis")
      .style("font-size", "12px")
      .attr("transform", "translate(0, " + (height - margin.top - margin.bottom) + ")")
      .transition()
      .call(xAxis);

    // text label for the x axis
    svg
      .append("text")
      .attr("transform", "translate(" + width / 2 + " ," + (height + margin.top + 20) + ")")
      .style("text-anchor", "middle")
      .text("Most Recent Hour");

    svg.append("g").attr("class", "y axis leadGridAxis").style("font-size", "12px").transition().call(yAxis);

    return svg;
  }

  // gridlines in x axis function
  function _make_x_gridlines() {
    return d3.axisBottom(x).ticks(tickFrequency);
  }

  if (options.hasOwnProperty("timeDomain")) {
    [timeDomainStart, timeDomainEnd] = options.timeDomain;
  }

  /**
   *  @param {string} value The value can be
   *                  "fit" - the domain fits the data or
   *                  "fixed" - fixed domain.
   */
  if (options.hasOwnProperty("timeDomainMode")) {
    timeDomainMode = options.timeDomainMode;
  }

  if (options.hasOwnProperty("channels")) {
    channels = options.channels;
  }

  if (options.hasOwnProperty("tickFrequency")) {
    tickFrequency = options.tickFrequency;
  }

  if (options.hasOwnProperty("tickFormat")) {
    tickFormat = options.tickFormat;
  }

  return ganttLeads;
}
