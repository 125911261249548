import * as d3 from "d3";
import {DateTime, Duration, Interval} from "luxon";

/* eslint-disable */
/**
 * @author Dimitry Kudrayvtsev
 * @version 2.0
 *
 * Ported to d3 v4 by Keyvan Fatehi on October 16th, 2016
 *
 */

export default function gantt(parentElement, elementId, dayOfStudyData = {}, options = {}) {
  var FIT_TIME_DOMAIN_MODE = "fit";
  var FIXED_TIME_DOMAIN_MODE = "fixed";
  var MIN_EVENT_WIDTH = 1;

  var margin = {
    top: 20,
    right: 30,
    bottom: 20,
    left: 60,
  };
  if (parentElement.id === "arrhythmiaTimelineContainer") {
    // Margins for Generated Report
    margin.right = 10;
    margin.left = 50;
  }
  var display = "rect";
  var timeDomainStart = DateTime.now().minus({days: 3});
  var timeDomainEnd = DateTime.now().plus({hours: 3});
  var timeDomainMode = FIT_TIME_DOMAIN_MODE; // fixed or fit
  var taskTypes = [];
  // Default is Daily Trend Values
  var tickFrequency = d3.utcHour.every(1);
  var tickFormat = d3.timeFormat("%H:%M");
  var labelFrequency = d3.utcHour.every(4);

  var height = 280;
  var width = parentElement.offsetWidth - margin.right - margin.left - 20;
  var itemHeight = (height - margin.top - margin.bottom) * 0.33;

  function keyFunction(d) {
    return d.startDate + d.taskName + d.endDate;
  }

  function rectTransform(d) {
    let offset = itemHeight;
    if (d.display === "circle") {
      offset = itemHeight - taskTypes.length + 3;
    }
    const yPos = y(d.taskName) + offset;

    return "translate(" + x(d.startDate) + "," + yPos + ")";
  }

  var x, y, xAxis, yAxis;

  initAxis();

  function initTimeDomain(tasks) {
    if (timeDomainMode === FIT_TIME_DOMAIN_MODE) {
      if (tasks === undefined || tasks.length < 1) {
        timeDomainStart = d3.utcDay.offset(new Date(), -3);
        timeDomainEnd = d3.utcHour.offset(new Date(), +3);
        return;
      }
      tasks.sort(function (a, b) {
        return a.endDate - b.endDate;
      });
      timeDomainEnd = tasks[tasks.length - 1].endDate;
      tasks.sort(function (a, b) {
        return a.startDate - b.startDate;
      });
      timeDomainStart = tasks[0].startDate;
    }
  }

  function initAxis() {
    itemHeight = ((height - margin.top - margin.bottom) / taskTypes.length) * 0.33;

    x = d3
      .scaleUtc()
      .domain([timeDomainStart.toJSDate(), timeDomainEnd.toJSDate()])
      .range([0, width])
      .clamp(true);

    y = d3
      .scaleBand()
      .domain(taskTypes)
      .rangeRound([0, height - margin.top - margin.bottom], 0.1);

    xAxis = d3.axisBottom().ticks(labelFrequency).scale(x).tickFormat(tickFormat).tickSize(0);

    yAxis = d3.axisLeft().scale(y).tickSize(0);
  }

  function gantt(tasks, isDisabled) {
    const disabledClass = isDisabled ? " disabled" : "";

    initTimeDomain(tasks);
    initAxis();

    const unreadableEvents = [];
    const arrhythmiaEvents = [];
    const y1 = y(taskTypes[taskTypes.length - 1]) + y.bandwidth(); // Bottom
    const y2 = y(taskTypes[0]); // Top

    tasks.forEach((task) => {
      if (task.taskName === "Unreadable") {
        unreadableEvents.push(task);
      } else {
        arrhythmiaEvents.push(task);
      }
    });

    var svg = d3
      .select(`#${elementId}`)
      .append("svg")
      .attr("class", "chart")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      // Subtract 27 from the right margin so that the chart lines up with the heart rate trend
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right - 27} ${height + margin.top + margin.bottom}`
      )
      .classed("ganttChartSvg", true)
      .append("g")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .attr("preserveAspectRatio", "none");

    // Make vertical gridlines
    svg
      .append("g")
      .attr("class", `grid${disabledClass}`)
      .attr("transform", "translate(0, " + (height - margin.top - margin.bottom) + ")")
      .call(
        _make_x_gridlines()
          .tickSize(-(height - margin.top - margin.bottom))
          .tickFormat("")
      );

    // Make horizontal gridlines
    svg
      .append("g")
      .attr("class", `grid${disabledClass}`)
      .attr("transform", "translate(0, " + (itemHeight + itemHeight / 2) + ")")
      .call(_make_y_gridlines().tickSize(-width).tickFormat(""));

    // Add date lines
    const isDailyTrend = timeDomainEnd - timeDomainStart === Duration.fromObject({days: 1}).toMillis();
    const days = Interval.fromDateTimes(timeDomainStart, timeDomainEnd)
      .splitBy({days: 1})
      .map((i) => (isDailyTrend ? i.end.startOf("day") : i.start));
    days.forEach((day) => {
      let dayOfStudyText = "";

      if (
        dayOfStudyData &&
        Interval.fromDateTimes(dayOfStudyData.firstDay, dayOfStudyData.lastDay).contains(day)
      ) {
        const diffInDays = day.diff(dayOfStudyData.firstDay, "days").toObject().days + 1;
        dayOfStudyText = ` - d${diffInDays}`;
      }

      _showDateLines(svg, x(day.toJSDate()), y1, y2, day, dayOfStudyText, disabledClass);
    });

    const unreadableY1 = y(taskTypes[0]);
    const unreadableY2 = y(taskTypes[taskTypes.length - 1]) + y.bandwidth();
    unreadableEvents.forEach((unreadableEvent) => {
      let eventWidth = x(unreadableEvent.endDate) - x(unreadableEvent.startDate);
      if (eventWidth < MIN_EVENT_WIDTH) {
        eventWidth = MIN_EVENT_WIDTH;
      }
      svg
        .append("rect")
        .attr("x", x(unreadableEvent.startDate))
        .attr("y", unreadableY1)
        .attr("width", eventWidth)
        .attr("height", unreadableY2)
        .classed(`artifactRegion${disabledClass}`, true);
    });

    svg
      .selectAll(".chart")
      .data(arrhythmiaEvents, keyFunction)
      .enter()
      .append(function (d, i) {
        return document.createElementNS(d3.namespaces.svg, "display" in d ? d.display : display);
      })
      .attr("cx", 0)
      .attr("cy", 10)
      .attr("r", itemHeight / 2)
      .attr("class", function (d) {
        const colorClass = d.taskName === "Unclassified" ? " grey" : "";
        return `timelineBar${colorClass}${disabledClass}`;
      })
      .attr("y", 0)
      .attr("transform", rectTransform)
      .attr("height", itemHeight)
      .attr("width", function (d) {
        let eventWidth = x(d.endDate) - x(d.startDate);
        if (eventWidth < MIN_EVENT_WIDTH) {
          eventWidth = MIN_EVENT_WIDTH;
        }
        return eventWidth;
      });

    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0, " + (height - margin.top - margin.bottom) + ")")
      .transition()
      .call(xAxis);

    svg.append("g").attr("class", "y axis wrappedLabel").transition().call(yAxis);

    // This waits for the canvas to load
    angular.element(wrapLabels);
    angular.element(() => setTimeout(wrapLabels, 250));

    return svg;
  }

  function wrapLabels() {
    d3.selectAll(".wrappedLabel text").each(function (label) {
      const textElement = d3.select(this);
      const words = label?.split(/\s+/) || [];

      if (words.length > 1) {
        let dy = `-${(words.length - 1) * 0.32}em`; // Set the initial line Y position
        textElement.text(null); // Delete the original text content
        words.forEach((word) => {
          textElement
            .append("tspan") // Replace text with one tspan per word
            .attr("x", textElement.attr("x"))
            .attr("dy", dy)
            .text(word);
          dy = "1.1em";
        });
      }
    });
  }

  // gridlines in x axis function
  function _make_x_gridlines() {
    return d3.axisBottom(x).ticks(tickFrequency);
  }

  // gridlines in y axis function
  function _make_y_gridlines() {
    return d3.axisLeft(y).ticks(taskTypes.length - 1);
  }

  // Date Indicators with triangle markers
  function _showDateLines(svg, x1, y1, y2, date, dateText, disabledClass) {
    const color = disabledClass === "" ? "#76858c" : "#c7c7c7";
    const strokeWidth = 2;
    const triangleHeight = 20;
    const triangleWidth = 15;

    svg
      .append("line")
      .attr("stroke", color)
      .attr("stroke-width", strokeWidth)
      .attr("x1", x1)
      .attr("x2", x1)
      .attr("y1", y1)
      .attr("y2", y2 - triangleHeight);
    svg
      .append("text")
      .attr("class", `dateLineText${disabledClass}`)
      .attr("text-anchor", "left")
      .attr("x", x1 + triangleWidth + 5)
      .attr("y", y2 - triangleWidth / 2)
      .text(`${date.toFormat("LLL dd")}${dateText}`);

    // m is pen down, capital means absolute coordinates
    // l is lineto, lowercase means relative coordinates
    // h and v are horizontal and vertical lineto
    let triangleData = `M ${x1 + strokeWidth / 2} 0`;
    triangleData += ` l ${triangleWidth} -${triangleHeight / 2}`;
    triangleData += ` l -${triangleWidth} -${triangleHeight / 2}`;
    triangleData += ` h -${strokeWidth}`;
    triangleData += ` v ${triangleHeight}`;
    svg.append("path").attr("d", triangleData).attr("fill", color);
  }

  if (options.hasOwnProperty("timeDomain")) {
    [timeDomainStart, timeDomainEnd] = options.timeDomain;
  }

  /**
   *  @param {string} value The value can be
   *                  "fit" - the domain fits the data or
   *                  "fixed" - fixed domain.
   */
  if (options.hasOwnProperty("timeDomainMode")) {
    timeDomainMode = options.timeDomainMode;
  }

  if (options.hasOwnProperty("taskTypes")) {
    taskTypes = options.taskTypes;
  }

  if (options.hasOwnProperty("tickFrequency")) {
    tickFrequency = options.tickFrequency;
  }

  if (options.hasOwnProperty("tickFormat")) {
    tickFormat = options.tickFormat;
  }

  if (options.hasOwnProperty("labelFrequency")) {
    labelFrequency = options.labelFrequency;
  }

  return gantt;
}
